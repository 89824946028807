<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    style="padding-top: 0"
  >
    <!--弹出上传对话框 -->
    <v-dialog
      v-model="dialog"
      max-width="600"
      persistent>
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green"
          >
            <div slot="header">
              <div class="title font-weight-bold mb-2 d-flex">
                <span>上传固件</span>
                <span class="text-xs-right">
                  <v-icon
                    size="24"
                    @click="closeUploadDialog">
                    mdi-close-circle
                  </v-icon>
                </span>
              </div>
              <div class="category font-weight-medium">
                仅限.bin文件，且大小不超过10M
              </div>
            </div>
            <v-container py-2>
              <v-layout wrap>
                <v-flex
                  xs12
                  md12
                >
                  <v-text-field
                    ref="uploadfile"
                    :value="uploadDialog.filename"
                    label="File"
                    prepend-inner-icon="mdi-link-variant"
                    readonly
                    style="margin-top:20px"
                    @click.stop="openFile"
                    @keyup.enter="submitFile"
                  />
                  <input
                    id="inputHidden"
                    ref="inputHidden"
                    type="file"
                    accept=".bin"
                    @change="inputChanged">
                </v-flex>
                <v-flex
                  xs4
                  md4
                >
                  <v-text-field
                    v-model="uploadDialog.cid"
                    label="cid"
                    required
                    @keyup.enter="submitFile"
                  />
                </v-flex>
                <v-flex
                  xs4
                  md4
                >
                  <v-text-field
                    v-model="uploadDialog.vid"
                    label="vid"
                    required
                    @keyup.enter="submitFile"
                  />
                </v-flex>
                <v-flex
                  xs4
                  md4
                >
                  <v-text-field
                    v-model="uploadDialog.pid"
                    label="pid"
                    required
                    @keyup.enter="submitFile"
                  />
                </v-flex>
                <v-flex
                  xs3
                  md3
                >
                  <v-text-field
                    v-model="uploadDialog.moduleName"
                    label="模块名称"
                    required
                    @keyup.enter="submitFile"
                  />
                </v-flex>
                <v-flex
                  xs2
                  md2
                >
                  <v-text-field
                    v-model="uploadDialog.hardwareVersion"
                    label="硬件版本"
                    required
                    @keyup.enter="submitFile"
                  />
                </v-flex>
                <v-flex
                  xs2
                  md2
                >
                  <v-text-field
                    v-model="uploadDialog.firmwareVersion"
                    label="固件版本"
                    required
                    @keyup.enter="submitFile"
                  />
                </v-flex>
                <v-flex
                  xs2
                  md2
                >
                  <v-text-field
                    v-model="uploadDialog.clientVersion"
                    label="客户版本"
                    required
                    @keyup.enter="submitFile"
                  />
                </v-flex>
                <v-flex
                  xs3
                  md3
                >
                  <v-text-field
                    v-model="uploadDialog.dateVersion"
                    label="日期版本"
                    required
                    @keyup.enter="submitFile"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            :class="showSubmitBtn"
            xs12
            text-xs-right
          >
            <v-btn
              class="mx-1 font-weight-medium"
              color="success"
              style="margin-right: 20px"
              @click.prevent="submitFile">
              提交
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出上传对话框 E-->
    <v-flex
      md12
    >
      <v-card-title
        style="padding: 0"
      >
        <v-flex
          xs12
          md9
          style="padding: 0"
        >
          <v-btn
            class="mx-3 font-weight-medium"
            color="green"
            @click.prevent="openUploadDialog">
            <v-icon>mdi-cloud-upload
            </v-icon>
            <span class="mx-2 font-weight-medium">上传固件</span>
          </v-btn>
        </v-flex>
        <v-flex
          xs12
          md3
          style="padding: 0"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-flex>
      </v-card-title>
      <material-card
        color="green"
        style="margin-top: 30px"
      >
        <div
          slot="header"
          class="d-flex">
          <span>
            <div class="title font-weight-bold mb-2">
              固件列表信息
            </div>
          </span>
          <span
            style="text-align:right;margin-top: -10px">
            <div>
              <span style="margin-right:20px;">
                <v-btn
                  small
                  round
                  class="mx-0 font-weight-medium"
                  color="transparent"
                  style="margin-right:20px"
                  @click="fileList"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </span>
            </div>
          </span>
        </div>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-3 text-xs-left"
          hide-actions
        >
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-regular text-success text--darken-3 text-xs-left"
              v-text="header.text"
            />
          </template>
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td class="font-weight-light">{{ item.id }}</td>
            <td class="font-weight-regular">{{ item.moduleName }}</td>
            <td class="font-weight-light">{{ item.cid }}</td>
            <td class="font-weight-light">{{ item.vid }}</td>
            <td class="font-weight-light">{{ item.pid }}</td>
            <td class="font-weight-light">{{ item.hardwareVersion }}</td>
            <td class="font-weight-light">{{ item.firmwareVersion }}</td>
            <td class="font-weight-light">{{ item.clientVersion }}</td>
            <td class="font-weight-light">{{ item.dateVersion }}</td>
            <td class="font-weight-light">{{ item.srcName }}</td>
            <td class="font-weight-light">{{ $formatFileSize(item.filesize) }}</td>
            <!-- <td class="font-weight-light">{{ $getDateTimeString(item.createdAt) }}</td>-->
            <td class="font-weight-light">{{ $getDateTimeString(item.updatedAt) }}</td>
            <td
              v-if="item.synced"
              class="font-weight-regular primary--text">
              {{ displaySynced(item.synced) }}</td>
            <td
              v-else
              class="font-weight-regular red--text"
              @click.prevent="sync(item)">
              {{ displaySynced(item.synced) }}</td>
            <td class="font-weight-light">{{ $getDateTimeString(item.syncedAt) }}</td>
            <td
              v-if="item.enabled"
              class="font-weight-regular primary--text"
              @click.prevent="disable(item)">
              {{ displayEnabled(item.enabled) }}</td>
            <td
              v-else
              class="font-weight-regular red--text"
              @click.prevent="enable(item)"
            >{{ displayEnabled(item.enabled) }}</td>
            <td>
              <div>
                <v-btn
                  slot="activator"
                  class="v-btn--simple"
                  color="red"
                  icon
                  @click.prevent="openEditDialog(item)"
                >
                  <v-icon>mdi-file-document-edit-outline</v-icon>
                </v-btn>
              </div>
            </td>
            <!--            <td>-->
            <!--              <div>-->
            <!--                <v-btn-->
            <!--                  slot="activator"-->
            <!--                  class="v-btn&#45;&#45;simple"-->
            <!--                  color="red"-->
            <!--                  icon-->
            <!--                  @click.prevent="openDelDialog(item)"-->
            <!--                >-->
            <!--                  <v-icon>mdi-delete-forever-outline</v-icon>-->
            <!--                </v-btn>-->
            <!--              </div>-->
            <!--            </td>-->
          </template>

        </v-data-table>
      </material-card>
    </v-flex>

    <!--弹出的删除确认框 S-->
    <v-dialog
      v-model="showDelete"
      max-width="600"
      persistent
      @keydown.esc="showDelete = false"
    >
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green"
          >
            <div
              slot="header"
            >
              <div class="mb-2 d-flex">
                <span class="title font-weight-bold mb-2">文件名验证</span>
                <span class="text-xs-right">
                  <v-icon
                    size = "24"
                    @click = "closeDelDialog"
                  >mdi-close-circle</v-icon>
                </span>
              </div>
              <div>
                <p class="category font-weight-medium">输入文件名后确认删除：<span class="title text-warning font-weight-bold">{{ delfilename }}</span></p>
              </div>
            </div>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                v-focus
                id="delinput"
                ref="delinput"
                v-model="dialogfilename"
                label="文件名"
                required
                @keyup.enter="deleteFile"
              />
              <div style="display: none">
                <v-text-field
                  v-model="delfilename"
                  label="文件名"
                />
              </div>
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px"
          >
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="deleteFile"
            >
              确认删除
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出的删除确认框 E-->

    <v-snackbar
      :color="color"
      :bottom="bottom"
      :top="top"
      :left="left"
      :right="right"
      v-model="snackbar"
      dark
    >
      <v-icon
        color="white"
        class="mr-3"
      >
        mdi-alert-circle
      </v-icon>
      <div>{{ text }}</div>
      <v-icon
        size="16"
        @click="snackbar = false"
      >
        mdi-close-circle
      </v-icon>
    </v-snackbar>

    <div
      id="app"
    >

      <transition
      >
        <div
          v-if="flag"
        >
          <div
            class="d-flex"/>
          <v-card
            class="v-card--material__header"
            color="green">
            <v-card-title
              class="title font-weight-light mb-2"
              color="#fff">
              <v-flex xs10>
                文件上传列表
              </v-flex>
              <v-flex
                xs1
                text-xs-right
              >
                <span :class="showhideMinFlag">
                  <v-icon
                    size="24"
                    color="#fff"
                    @click="minUploadList">
                    mdi-chevron-down-circle-outline
                  </v-icon>
                </span>
                <span :class="showhideMaxFlag">
                  <v-icon
                    size="24"
                    color="#fff"
                    @click="maxUploadList">
                    mdi-chevron-up-circle-outline
                  </v-icon>
                </span>
              </v-flex>
              <v-flex
                xs1
                text-xs-right
              >
                <span >
                  <v-icon
                    size="24"
                    color="#fff"
                    @click="closeUploadList">
                    mdi-close-circle-outline
                  </v-icon>
                </span>
              </v-flex>
            </v-card-title>
            <div :class="showhideUploadList">
              <v-data-table
                :headers="uploadHeaders"
                :items="uploadItems"
                hide-actions
              >
                <th>文件名称</th>
                <th>文件大小</th>
                <th>上传状态</th>

                <template
                  slot="items"
                  slot-scope="{ item }"
                  persistent
                >
                  <td class="font-weight-regular">{{ item.filename }}</td>
                  <td class="font-weight-light">{{ item.size }}</td>
                  <td class="font-weight-light">{{ item.uploaddate }}</td>
                  <td class="font-weight-light">
                    <v-progress-linear
                      :value="item.progress"
                      class="d-flex"
                      color="green"
                      height="25"
                      reactive
                    >
                      <div class="text-xs-center">
                        <strong >{{ item.progress }}</strong>
                      </div>
                    </v-progress-linear>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </div>
      </transition>
    </div>
  </v-container>
</template>

<script>

export default {
  directives: {
    focus (el) {
      // console.log(el)
      // el.focus()
      let delinput = document.getElementById('delinput')
      // console.log(delinput)
      if (delinput !== undefined && delinput != null) {
        delinput.focus()
      }
    }
  },
  data: () => ({
    search: '',
    uploadDialog: {
      filename: '',
      moduleName: '',
      cid: '',
      vid: '',
      pid: '',
      hardwareVersion: '',
      firmwareVersion: '',
      clientVersion: '',
      dateVersion: ''
    },
    flag: false,
    filename: '',
    dialogfilename: '',
    delfilename: '',
    srcname: '',
    dialog: false,
    showDelete: false,
    file: [],
    src: '',
    top: true,
    bottom: false,
    left: false,
    right: false,
    snackbar: false,
    color: '',
    text: '',
    progress: 0,
    showSubmitBtn: 'd-flex',
    showhideUploadList: 'd-flex',
    count: 0, // id值
    showhideMinFlag: 'd-flex',
    showhideMaxFlag: 'd-none',
    headers: [
      { sortable: false, text: '#', value: 'id' },
      { sortable: false, text: '模块名称', value: 'moduleName' },
      { sortable: false, text: 'cid', value: 'cid' },
      { sortable: false, text: 'vid', value: 'vid' },
      { sortable: false, text: 'pid', value: 'pid' },
      { sortable: false, text: '硬件', value: 'hardwareVersion' },
      { sortable: false, text: '固件', value: 'firmwareVersion' },
      { sortable: false, text: '客户', value: 'clientVersion' },
      { sortable: false, text: '日期', value: 'dateVersion' },
      { sortable: false, text: '文件名', value: 'srcName' },
      { sortable: false, text: '大小', value: 'filesize' },
      // { sortable: false, text: '上传日期', value: 'createdAt' },
      { sortable: false, text: '日期', value: 'updatedAt' },
      { sortable: false, text: '同步', value: 'synced' },
      { sortable: false, text: '同步日期', value: 'syncedAt' },
      { sortable: false, text: '有效', value: '' },
      { sortable: false, text: '编辑', value: '' }
    ],
    uploadHeaders: [
      { sortable: false, text: '文件名称', value: 'uploadfilename' },
      { sortable: false, text: '大小', value: 'uploadfilesize' },
      { sortable: false, text: '上传日期', value: 'uploaddate' },
      { sortable: false, text: '状态', value: 'uploadstatus' }
    ],
    items: [],
    uploadItems: []
  }),
  created () {
    this.fileList()
  },
  methods: {
    displayEnabled (value) {
      if (value) {
        return '有效'
      }
      return '无效'
    },
    displaySynced (value) {
      if (value) {
        return '已同步'
      }
      return '未同步'
    },
    sync (item) {
      console.log('sync()', item)
    },
    enable (item) {
      console.log('enable()', item)
    },
    disable (item) {
      console.log('disable()', item)
    },
    openUploadDialog () {
      // console.log('openUploadDialog ()')
      this.clearFile()
      this.dialog = true
      this.showSubmitBtn = 'd-flex'
      this.$refs.inputHidden.value = ''
      this.uploadDialog.filename = ''
      this.uploadDialog.moduleName = ''
      this.uploadDialog.cid = ''
      this.uploadDialog.vid = ''
      this.uploadDialog.pid = ''
      this.uploadDialog.hardwareVersion = ''
      this.uploadDialog.firmwareVersion = ''
      this.uploadDialog.clientVersion = ''
      this.uploadDialog.dateVersion = ''
    },
    closeUploadDialog () {
      this.$refs.inputHidden.value = ''
      this.dialog = false
    },
    openFile () {
      this.$refs.inputHidden.value = ''
      this.filename = ''
      let uploadBtn = this.$refs.inputHidden
      uploadBtn.click()
    },
    clearFile () {
      this.$refs.inputHidden.value = ''
      this.filename = ''
    },
    inputChanged (event) {
      console.log('inputChanged()', event)
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i] === undefined || event.target.files[i] == null) {
          this.$refs.inputHidden.value = ''
          this.filename = ''
        } else {
          this.file.push(event.target.files[i]) // 获取文件
          let name = event.target.files[i].name
          let size = event.target.files[i].size
          if (size > 10 * 1024 * 1024) {
            this.$snackbar.error('请选择小于10M的文件')
          }
          if (name.length > 50) {
            this.$snackbar.error('文件名过长，请输入少于50个字符')
          }
          if (i === 0) {
            this.uploadDialog.filename = name + ' (' + this.$formatFileSize(size) + ')'
            // let name = 'WM06H1S1.0.0_20201013.bin'
            let moduleName = name.slice(0, 4)
            let hardwareVersion = name.slice(name.indexOf("H") + 1, name.indexOf("S"))
            let firmwareVersionFull = name.slice(name.indexOf("S") + 1, name.indexOf("_"))
            let firmwareVersionSplit = firmwareVersionFull.split('.')
            let firmwareVersion = firmwareVersionSplit[0] + firmwareVersionSplit[1]
            let clientVersion = firmwareVersionSplit[2]
            let dateVersion = name.slice(name.indexOf("_") + 1, name.indexOf("_") + 1 + 8)
            // console.log('moduleName', moduleName)
            // console.log('hardwareVersion', hardwareVersion)
            // console.log('firmwareVersion', firmwareVersion)
            // console.log('clientVersion', clientVersion)
            // console.log('dateVersion', dateVersion)
            this.uploadDialog.moduleName = moduleName
            this.uploadDialog.hardwareVersion = hardwareVersion
            this.uploadDialog.firmwareVersion = firmwareVersion
            this.uploadDialog.clientVersion = clientVersion
            this.uploadDialog.dateVersion = dateVersion
          } else {
            this.uploadDialog.filename = this.uploadDialog.filename + ',' + name + ' (' + this.$formatFileSize(size) + ')'
          }
        }
      }
    },
    submitFile () {
      let files = this.$refs.inputHidden.files
      if (this.uploadDialog.filename.trim() === '' ||
        this.uploadDialog.cid.trim() === '' ||
        this.uploadDialog.vid.trim() === '' ||
        this.uploadDialog.pid.trim() === '' ||
        this.uploadDialog.hardwareVersion.trim() === '' ||
        this.uploadDialog.firmwareVersion.trim() === ''
      ) {
        this.$snackbar.error('输入内容')
        return
      }
      if (files.length > 1) {
        this.$snackbar.error('每次最多可上传1个文件')
        return
      }
      if (this.uploadItems.length + files.length > 9) {
        this.$snackbar.error('每次最多可上传1个文件')
        return
      }

      if (files.length <= 0) return
      let size = ''
      let filename = ''
      for (let i = 0; i < files.length; i++) { // 多文件
        // files[i].progress = this.progress
        size = files[i].size
        filename = files[i].name
        if (size > 10 * 1024 * 1024) {
          this.$snackbar.error('请选择小于10M的文件')
          return
        }
        if (filename.length > 50) {
          this.$snackbar.error('文件名过长，请选择小于10M的文件')
          return
        }

        let obj = {
          'count': this.count,
          'filename': filename,
          'size': this.$formatFileSize(size),
          'progress': 0,
          'uploaddate': this.$getDateTimeString()
        }
        this.uploadItems.push(obj)
      }
      this.flag = 'true'
      for (let i = 0; i < files.length; i++) {
        let count = this.count
        let config = this.$authHeaders()
        config.onUploadProgress = progressEvent => {
          console.log('progressEvent:', progressEvent)
          let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
          this.uploadItems[count].progress = complete
          if (progressEvent.loaded === progressEvent.total) {
            this.closeUploadList()
          }
        }
        this.count = this.count + 1
        let formData = new FormData()
        formData.append('files', this.file[i])
        formData.append('moduleName', this.uploadDialog.moduleName)
        formData.append('cid', this.uploadDialog.cid)
        formData.append('pid', this.uploadDialog.pid)
        formData.append('vid', this.uploadDialog.vid)
        formData.append('hardwareVersion', this.uploadDialog.hardwareVersion)
        formData.append('firmwareVersion', this.uploadDialog.firmwareVersion)
        formData.append('clientVersion', this.uploadDialog.clientVersion)
        formData.append('dateVersion', this.uploadDialog.dateVersion)
        this.$http
          .post(this.$store.state.app.url + '/firmware/upload', formData, config)
          .then(res => {
            let data = res.data
            if (data.errCode === 0) {
              this.fileList()
              if (this.uploadItems === undefined || this.uploadItems.length <= 0) {
                this.flag = false
                this.count = 0
              }
            } else {
              console.log(data.errMsg)
              this.$snackbar.error(data.errMsg)
            }
          }, (error) => {
            console.log(error.message)
          })
        if (this.uploadItems[i].progress === '100%') {
          this.uploadItems[i].classname = 'd-none'
        }
      }

      this.closeUploadDialog()
    },
    minUploadList () {
      this.showhideUploadList = 'd-none'
      this.showhideMinFlag = 'd-none'
      this.showhideMaxFlag = 'd-flex'
    },
    maxUploadList () {
      this.showhideUploadList = 'd-flex'
      this.showhideMinFlag = 'd-flex'
      this.showhideMaxFlag = 'd-none'
    },
    closeUploadList () {
      this.flag = false
      this.uploadItems = []
      this.count = 0
    },
    fileList () {
      this.items = []
      // console.log(this.$store.state.app.signIn.token)
      let config = this.$authHeaders()
      config.params = {
        // moduleName: 'BM16',
        // cid: 13,
        size: 100,
        page: 0
      }
      this.$http
        .get(this.$store.state.app.url + '/firmware/getList', config)
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            let result = data.result
            console.log(result)
            this.items = result.content
          } else {
            console.log(data.errMsg)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    openEditDialog (item) {
      console.log('openEditDialog()', item)
    },
    closeEditDialog () {
      console.log('closeEditDialog()')
    },
    openDelDialog (item) {
      console.log('openDelDialog()', item)
      // this.showDelete = true
      // this.delfilename = item.srcName
      // this.srcname = item.srcName
    },
    closeDelDialog () {
      this.showDelete = false
      this.dialogfilename = ''
    },
    deleteFile () {
      if (this.delfilename !== this.dialogfilename) {
        this.$snackbar.error('输入不正确，请重新输入')
        return
      }
      let config = {}
      this.$http.post(this.$store.state.app.fileUrl + '/firmware/delete', config, this.$authHeaders())
        .then(res => {
          let data = res.data
          if (data.errCode === 0) {
            let result = data.result
            console.log(result)
            this.projectList()
            this.$snackbar.success('删除成功')
            this.closeAddDialog()
          } else {
            console.log(data.errMsg)
            this.$snackbar.error(data.errMsg)
          }
        }, (error) => {
          this.$httpCatch(error)
        })
    }
  }
}
</script>
<style scoped>
  #inputHidden {
    height: 0;
    width: 0;
    visibility: hidden;
  }
   #app{
     opacity: 1;
     position: fixed;
     bottom: 0;
     right: 0;
     width: 800px;

    /*解决出现横向滚动条问题*/
     overflow-x: hidden;
     overflow-y: hidden;
  }
  .v-enter {
    opacity: 0;
    transform: translateY(100%);
    position: absolute;
  }
  .v-leave-to {
    transform: translateY(100%);
  }
  .v-enter-active,.v-leave-active {
    transition: all 0.4s ease
  }

</style>
